.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

/* Thin, stylish scrollbars. Not just for vanity sake, defining these webkit-scrollbar styles
   solves problem of "disappearing" scrollbars on ChromeOS, which is confusing to users.
   (scrollbars are the only visual cue that there is additional content in panel)
*/
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

.SelectedGlyph {
  top: 4px !important;
  left: 4px !important;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url('assets/SelectedGlyph.png');
  background-size: 13px 12px;
}

.LightSelectedGlyph {
  top: 4px !important;
  left: 4px !important;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url('assets/LightSelectedGlyph.png');
  background-size: 13px 12px;
}

.SelectedGlyphWithBreakpoint {
  top: 4px !important;
  left: 4px !important;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url('assets/SelectedGlyphWithBreakpoint.png');
  background-size: 13px 12px;
}

.LightSelectedGlyphWithBreakpoint {
  top: 4px !important;
  left: 4px !important;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url('assets/LightSelectedGlyphWithBreakpoint.png');
  background-size: 13px 12px;
}

.HoverBreakpoint {
  top: 6px !important;
  left: 5px !important;
  background-repeat: no-repeat;
  cursor: pointer;
  background-image: url('assets/HoverBreakpoint.png');
  background-size: 8px 8px;
}
.Breakpoint {
  top: 6px !important;
  left: 5px !important;
  background-repeat: no-repeat;
  cursor: pointer;
  background-image: url('assets/Breakpoint.png');
  background-size: 8px 8px;
}
.HighlightedLine {
  background: #ffff0033;
}
.LightHighlightedLine {
  background: #ffff6673;
}
.katex .fbox {
  border: 0.04em solid #717171;  /* Until we can upgrade to new Katex which fixes \boxed border color for dark/light theme */
}